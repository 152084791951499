<template>
   <Login-Page :image=image  @login="login" ref="LoginPagechild"  />
</template>

<script>
import LoginPage from '@/pages/LoginPage.vue'
import API from '../api-config';

export default {
  components: { LoginPage },
  data(){
    return {
      image:"client-login.jpg",
    }
  },
  methods:{
     login:function(user){
         this.$http.post(API.APIendpoint+'client/token',user).then(response => {
           let responseHandle = response.body;
           this.$refs.LoginPagechild.removeLoadingGif();
           if(responseHandle.error === false){
             localStorage.setItem('token',responseHandle.data.token);
             this.$router.push({path: '/client'});
           }
           else{
            this.$swal.fire({
                icon: "error",
                title: "Erreur",
                text: responseHandle.msg,
                confirmButtonColor: "#3085d6",
              });
              this.$refs.LoginPagechild.hasError(responseHandle.msg);
              user.password = "";
           }
          }, response => {
          console.log(response.body);
        });
    }
  }

}
</script>

<style>

</style>