<template>
  <div class="body" style="min-height: 100vh">
    <div class="main">
      <div
        class="image-pub"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/' + image + '') + ')',
        }"></div>
      <div class="login-page">
        <div class="form">
          <div class="login">
            <div class="login-header">
              <h3 id="logintext" class="withouterror">Se connecter</h3>
            </div>
          </div>
          <form class="login-form">
            <input
              id="login"
              type="text"
              placeholder="Login"
              v-model="user.login"
            />
            <input
              id="password"
              type="password"
              placeholder="Mot de Passe"
              v-model="user.password"
            />
            <button
              id="submit"
              class="login-submit"
              @click.stop.prevent="submit($event)"
            >
              login
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image"],
  data: () => {
    return {
      user: {
        login: "",
        password: "",
      },
    };
  },
  methods: {
    submit(event) {
      document.getElementById("submit").classList.add("loading");
      this.$emit("login", this.user);
    },
    removeLoadingGif() {
      document.getElementById("submit").classList.remove("loading");
    },
    hasError(msg) {
      document.getElementById("login").classList.remove("error-border");
      document.getElementById("password").classList.remove("error-border");
      if (msg.search("Login") === 0) {
        document.getElementById("login").classList.add("error-border");
      } else {
        document.getElementById("password").classList.add("error-border");
      }
      console.log(msg.search("Mot de passe"));
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
/* header .header {
  background-color: #fff;
  height: 45px;
} */
header a img {
  width: 134px;
  margin-top: 4px;
}
.login-page {
  display: table-cell;
  width: 360px;
  height: 355px;
  margin: auto 0;
  font-family: Tahoma;
}
.login-page .form .login {
  margin-top: -31px;
  margin-bottom: 26px;
}
.form {
  position: relative;
  z-index: 1;
  background: #fefefe;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background-color: #1dd7ee;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form .message {
  margin: 15px 0 0;
  font-size: 12px;
}
.form .message a {
  text-decoration: none;
}
#element::-webkit-scrollbar {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
p {
  margin: 30px 0;
}
.body {
  /*   background-color: #1dd7ee; */
  min-height: 100% vh;
}
.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*   background-color: red; */

  min-height: 100vh;

  /*   padding: 30px; */
}

.image-pub {
  margin: auto 0;
  width: 360px;
  height: 355px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  background-size: cover;
  background-position: center;
}
.login-submit {
  margin-bottom: 30px;
}
.withouterror {
  margin-top: 38px;
  margin-bottom: 38px;
}
.withloginerror {
  margin-top: 5px;
  margin-bottom: 35px;
}
.withpassworderror {
  margin-top: 15px;
  margin-bottom: 35px;
}

h3 {
  margin-top: 15px;
  margin-bottom: 35px;
}
@media screen and (min-width: 1px) and (max-width: 1024px) {
  .image-pub {
    display: none;
  }
  .body {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.loading {
  background-image: url("../assets/images/loading.gif");
  height: 50px; /* You must set a specified height */
  background-position: center; /* Center the image */
  font-size: 0 !important;
  background-color: #f2f2f2 !important;
  border: 1px dotted !important;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.error-login {
  display: normal;
  margin-bottom: 10px;
}
.error-password {
  display: normal;
  margin-bottom: 20px;
}
.error-border {
  border: 1px solid red !important;
}
#password {
  margin-top: 0px;
}
</style>
